.contacthero {
  background-image: url("../../images/contact/conatactbanner.png");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 45vh;
}
.contact-top {
  display: flex;
  padding: 0rem 5rem;
  margin-top: -12rem;
  background-color: transparent;
  justify-content: space-between;
  gap: 30px;
}

.contact-address {
  width: 60%;
  margin-top: -3%;
}

.contact-form{
  margin-top: -4%;
}

.address-inner {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  margin-top: 3rem;
  background-color: #fff;
  border-radius: 15px;
  padding: 1rem;
  overflow: hidden;
}
.address-inner img {
  border-radius: 10px;
  width: 100%;
  height: 270px;
}
.contact-head {
  color: #585148;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.15px;
  margin-top: -10%;
  margin-left: 2rem;
  border-radius: 35px;
  background-color: #fff;
  padding: 1rem 2rem;
  display: inline-block;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.contact-blurb {
  margin-top: 2rem;
  padding: 0rem 2rem;
}
.contact i {
  background-color: #d4a54e1a;
}
.contact h4 {
  color: #585148;
  margin-bottom: 15px;
  font-size: 13px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  text-align: left;
  margin-top: 0;
}
.contact p {
  color: #585148;
  font-size: 14px;
  font-weight: 400;
}
.contactt-blurb-col {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-top: 2rem;
}
.contact-hr {
  height: 2px;
  background-color: #f2f2f2;
  width: 80%;
  margin: 6rem auto;
}

.contact hr {
  margin: 4rem 5rem 2rem;
  background-color: rgba(41, 39, 55, 0.07);
  border: none;
  height: 2px;
}
.contactt-branches {
  padding: 3rem 0rem;
  text-align: center;
}
.branch-hr {
  height: 2px;
  background-color: #d18f44;
  width: 5%;
  margin: 1rem auto;
}
.contact-branch-inner {
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 2rem 6rem;
  gap: 25px;
}
.branch-col {
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 1rem;
  border-radius: 15px;
  width: 360px;
}
.branch-col h5 {
  background-color: #d18f441e;
  font-size: 26px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  color: #d18f44;
  padding: 0.5rem;
  margin-bottom: 15px;
  text-align: center;
  border-radius: 10px;
}
.branch-col h4 {
  text-align: left;
}
.branch-col p {
  text-align: left;
}
.contact-map {
  overflow: hidden;
  border-radius: 15px;
  position: relative;
}
.contact-map .btn {
  position: absolute;
  bottom: 5%;
  display: inline-flex;
  z-index: 5;
  right: 2%;
  font-size: 12px;
  font-weight: 500;
  background-color: #d9a264;
  font-family: "Montserrat", sans-serif;
  padding: 1rem;
  border-radius: 50px;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  color: #fff;
}
.contact-map .btn i {
  margin-top: 5px;
  font-size: 14px;
  margin-right: 5px;
  vertical-align: middle;
}

.contactt-blurb-col .blurb:first-child{
  position: relative;
}
.contactt-blurb-col .blurb:first-child::after{
  content: '';
  position: absolute;
  right: -20%;
 width: 2px;
 height: 100%;
 background-color: #d4caca;
}

.address-inner img {
  width: 100%;
  object-fit: cover;
}

.contactt-blurb-col .blurb a, .con-blurb-content p a{
  text-decoration: none;
  color: #585148;
}

@media screen and (max-width: 1280px) {
  .contact-branch-inner {
    padding: 2rem 2rem;
  }
}

@media screen and (max-width: 1024px) {
  .contact-top {
    padding: 0rem 6rem;
    flex-direction: column;
    align-items: center;
  }
  .address-inner {
    width: 100%;
  }
  .address-inner img {
    width: 100%;
    object-fit: cover;
  }
  .contact-branch-inner {
    flex-wrap: wrap;
    gap: 30px;
  }
  .branch-col:last-child {
    margin: 0px auto;
  }
  .address-inner img {
    height: auto;
  }
}
@media screen and (max-width: 820px) {
  .contactt-blurb-col {
    flex-direction: column;
    align-items: flex-start;
  }
  .contact-blurb {
    padding: 0rem 0rem;
  }

  .contactt-blurb-col .blurb:first-child::after {
    display: none;
  }
  .contactt-blurb-col .blurb:nth-child(3) {
    padding-left: 2rem;
  }

  .contatop {
    padding: 0rem 2rem;
  }
  .contact-branch-inner {
    justify-content: center;
  }
  .contactt-blurb-col .blurb:first-child {
    position: relative;
  }
  .contactt-blurb-col .blurb:first-child::after {
    content: "";
    position: absolute;
    right: -18%;
    width: 2px;
    height: 100%;
    background-color: #d4caca;
  }
}
@media screen and (max-width: 400px) {

  .contact-top {
    padding: 0rem 1rem;
  }

  .contact-head {
    margin-left: 0px;
    padding: 1rem 1rem;
  }

  .contact-address {
    width: 100%;
  }

.contact-branch-inner {
  justify-content: center;
  padding: 0 1rem;
  display: block;
}

.branch-col {
  margin-bottom: 2rem;
}

}
