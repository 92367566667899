.heroslider {
  width: 100%;
  height: 90vh;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.slider {
  position: relative;
  width: 100%;
  height: 95vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.heroslider .swiper-wrapper {
  height: 100%;
  gap: 0;
}

/* Slider image styling */
.slider img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: opacity 0.5s ease-in-out;
}
.slider::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 45%;
  background: linear-gradient(
    180deg,
    rgba(29, 29, 29, 0) 0%,
    rgba(18, 18, 18, 0.99) 100%
  );
  pointer-events: none;
}

.heroslider .herocontent {
  position: absolute;
  bottom: 13%;
  left: 6%;
  z-index: 10;
  width: 45%;
}
.herocontent p {
  margin-bottom: 25px;
  color: #fff;
  font-family: "Encode Sans Expanded";
  background: rgba(29, 32, 41, 0.25);
  backdrop-filter: blur(5px);
  padding: 10px 20px;
  border-radius: 21px;
  display: inline-block;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 4.68px;
  text-transform: uppercase;
  white-space: nowrap;
}
.herocontent h4 {
  color: #fff;
  font-family: "Montserrat", sans-serif;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4em;
  text-transform: capitalize;
}
.heroslider .swiper-button-next {
  top: 80% !important;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #36332f !important;
  font-size: 14px;
  right: 8%;
  border-radius: 50px;
}

.heroslider .swiper-button-prev {
  top: 80% !important;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #36332f !important;
  font-size: 14px;
  border-radius: 50px;
  right: 12% !important;
  left: auto !important;
}

@media screen and (max-width: 1280px) {
  .heroslider .swiper-button-prev {
    right: 10% !important;
  }
}

@media screen and (max-width: 1024px) {
  .heroslider {
    height: 60vh;
    overflow: hidden;
  }
  .heroslider .slider {
    height: 60vh;
  }
  .heroslider .herocontent {
    width: 70%;
    bottom: 12%;
  }
  .heroslider .swiper-button-prev {
    margin-right: 2%;
  }
}


@media screen and (max-width: 480px) {
  nav {
    padding: 15px 0.5rem;
  }
  .heroslider .herocontent {
    bottom: 10%;
    width: 100%;
    text-align: left;
    left: 0;
    padding: 0 1rem;
    position: absolute;
  }

  .heroslider .herocontent p{
    font-size: 10px;
    display: none;
  }

  .heroslider .herocontent h4 {
    font-size: 16px;
    width: 70%;
  }

  .heroslider {
    height: 50vh;
    overflow: hidden;
  }
  .heroslider .slider {
    height: 50vh;
  }
  .heroslider .swiper-button-prev {
    top: 85% !important;
    margin-right: 10%;
    width: 30px;
    height: 30px;
  }
  .heroslider .swiper-button-next {
    top: 85% !important;
    width: 30px;
    height: 30px;
  }
}
