.design {
  padding: 6rem 3.5rem;
  background: #fcfcfc;
}

.designheader {
  display: flex;
}
.designheader .header2 {
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  font-weight: 400;
  line-height: 1.4em;
  letter-spacing: 0.36px;
}
.design-btn a{
  text-decoration: none;
  color: #fff;
}
.design-btn .btn2{
  padding: 9px 6px 9px 18px;
}
.designheader p {
  color: #323232;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  margin-left: 15%;
  width: 45%;
  letter-spacing: 0.00875rem;
}

.design-grid {
  margin-top: 5rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, auto);
  gap: 15px;
}
.design-grid .grid {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 15px;
}
.design-grid .bedroomimg {
  width: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  height: 100%;
}
.bedroomimg .design-btn {
  position: absolute;
  bottom: 10%;
}
.design-grid .grid3 img {
  width: 100%;
  object-fit: cover;
}

.design-grid img{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
  transition: transform 0.5s ease-in-out;
  transform: scale(1.3);
}

.design-grid .grid:hover img {
  animation: zoomEffect 1.7s ease-in-out forwards; /* Trigger animation on hover */
}

@keyframes zoomEffect {
  0% {
    transform: scale(1.1); /* Start at normal size */
  }
  50% {
    transform: scale(1); /* First zoom out slightly */
  }
  100% {
    transform: scale(1.2); /* Then zoom in */
  }
}

.design-grid .grid .design-btn {
  position: absolute;
  bottom: 5%;
  left: 2%;
}

.grid {
  overflow: hidden;
}
.grid1 {
  grid-column: 1 / 3;
  grid-row: 1;
}

.grid2 {
  grid-column: 3;
  grid-row: 1;
}

.grid3 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  grid-column: 4;
  grid-row: 1 / 3;
}

.grid4 {
  grid-column: 1;
  grid-row: 2;
}

.grid5 {
  grid-column: 2 / 4;
  grid-row: 2;
}

.grid6 {
  grid-column: 1 / 3;
  grid-row: 3;
}
.designmore {
  cursor: pointer;
  margin-top: 25px;
  border-radius: 15px;
  height: 25%;
  background-color: #d9a264;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  transition: all 5s ease-in-out;
}

.designmore h3 {
  color: #fff;
  font-family: "Encode Sans Expanded";
  font-size: 45px;
  letter-spacing: 0.45px;
}
.designmore p {
  color: #fff;
  font-size: 14px;
  font-family: "Encode Sans Expanded";
  margin-left: 10px;
}
.designmorearrow {
  vertical-align: middle;
  background-color: #e5b988b8;
  font-size: 16px;
  color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  margin: 5px 0px 0px 10px;
  transition: transform 0.3s ease;
}
.designmore:hover .designmorearrow {
  transform: translateX(20px);
  background-color: #fff;
  color: #323232;
}



.design-grid-mob{
  display: none;
}






@media (max-width: 1024px) {
  .designheader p {
    width: 65%;
  }
}



@media (max-width: 800px) {
  .design {
    padding: 5rem 2rem;
  }

  .design-grid {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, auto);
    gap: 10px;
  }

  .grid1 {
    grid-column: 1 / 4;
    grid-row: 1;
  }

  .grid2 {
    grid-column: 1 / 3;
    grid-row: 2;
  }

  .grid3 {
    grid-column: 3;
    grid-row: 2 / 3;
  }
  .designmore {
    margin-top: 10px;
  }

  .grid4 {
    grid-column: 1 / 4;
    grid-row: 3;
  }

  .grid5 {
    grid-column: 1 / 4;
    grid-row: 4;
  }
  .design-grid .grid3 .design-btn {
    bottom: 30% !important;
  }
  .designheader p {
    margin-left: 0px;
    width: 50%;
  }
  .designheader h2 {
    width: 50%;
  }

}
@media (max-width: 480px) {
  .designheader {
    display: flex;
    flex-direction: column;
  }
  .designheader p {
    width: 100%;
  }
  .designheader h2 {
    margin-bottom: 15px;
    width: 100%;
    font-size: 27px !important;
  }
  .design {
    padding: 2rem 1rem;
  }

.design-grid{
  display: none;
}

.design-grid-mob{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
  margin-top: 3rem;
}

.design-card{
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 15px;
  overflow: hidden; 
}

.design-card img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.5s ease-in-out;
  transform: scale(1.3);
}

.design-card .design-btn {
  position: absolute;
  bottom: 10%;
  left: 2%;
}


.design-grid-mob .design-card:hover img {
  animation: zoomEffect 1.7s ease-in-out forwards; /* Trigger animation on hover */
}
}
