.servicehero{
    background: url('../../images//package/packagehero.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 70vh;
    width: 100%;
    background-position: center;
}
.discount .smallhead{
    font-size: 15px;
    text-transform: uppercase;
}
  
.smallhead span{
    color: #d9a264;
}
.servicehero1{
  
    align-items: flex-end;
    margin-top: -25%;
    justify-content: space-between;
   padding-bottom: 2rem;
    background-color: transparent;
      display: flex;
      width: 100%;
      position: relative;
}
.servicehero1::after{
    content: '';
    width: 100%;
    height: 30vh;
    position: absolute;
    background-color: #fcfcfc;
    bottom: 0;
    z-index: -3;

}
.servicehero1col{
    padding: 0rem 4rem;
}
.servicehero1 .smallhead{
    margin-top: 1px;
}

.servicehero1col:last-child{
    margin-top: -25px;
    margin-right: 50px;
}

/*---slide container---*/
.serviceinteriorcol{
    flex: 1;
}
.serviceinterior{
    background-color: #fcfcfc;
    padding: 6rem 6rem 6rem 0rem;
    display: flex;
    gap: 35px;
    justify-content: space-between;
    align-items: center;
}
.service-slide{
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border: 20px solid #fcfcfc;
    border-radius: 15px;
    overflow: hidden;
    /* width: 85%;   */
    z-index: 2;
}
.sercol1{
    padding-left: 3rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sercol1::before{
    position: absolute;
    content: '';
    background-color: #F2F2F2;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    padding: 3rem 0rem;
    left: 0%;
    width: 50%;
    height: 100%;
}
.sercol1 .aboutslider{
    height: 60vh;
}
.hrline-bg{
    background-color: #fcfcfc;
    padding: 2rem 0rem;
}
.hrline-bg .hrline{
    margin:0px auto;
}
.servicegap{
    height: 4vh;
    
}

.service-desc{
    padding: 0 6rem;
    text-align: center;
}





@media screen and (max-width:1024px) {
    .servicehero{
        height: 50vh;
    }
    .servicehero1{
        margin-top: -27%;
        gap: 25px;
        flex-direction: column;
        align-items: center;
    }
    .servicehero1 .discount{
        margin: 0 auto;
        text-align: center;
        box-shadow: rgba(0, 0, 0, 0.18) 0px 3px 8px;
        margin-top: 25px;
    }
    .serviceinterior {
        flex-direction: column;
        padding: 2rem 0rem;
    }
    .sercol1::before{
        position: absolute;
        content: '';
        background-color: #F2F2F2;
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
  
        left: 0%;
        top: 0%;
        width:100%;
        height: 50%;
    
    }
    .serviceinterior .service-slide{
        margin-top: 4rem;
    }
    .sercol2{
        padding: 1rem 4rem;
    }
    /* .homeservicecard{
        flex-wrap: wrap;
    } */

    .homeservicecard{
        gap: 40px;
        margin: 3rem 1rem;
        }

    .homeservicecard .homesercardcol{
        width: 100%;
        margin-bottom: 25px;
        padding: 2rem 2rem;
        box-shadow: rgba(0, 0, 0, 0.18) 0px 3px 8px;
    }
    .serviceabt{
        padding: 2rem 1rem!important;
    }
    .serviceabtinner{
        flex-direction: column;
        gap: 25px;
    }
    .serviceabtinner .serviceabitinnercol1{
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .serviceabtinner .serviceabitinnercol{
        width: 100%;
    }
    .serviceabitinnercol1 .servblurb1{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .serviceabitinnercol1 .servblurb1 p{
        text-align: center;
    }

    .servicehero1col:last-child {
        margin-right: 0;
    }

    .service-main{
        margin-bottom: 50px;
    }

    .service-desc{
        padding: 0;
    }
}
@media screen and (max-width:800px) {
    .servicehero1 .discount{
        width: 100%;
        padding: 1rem;
    }
    .servicehero1 .discount .header2{
        font-size: 24px;
    }
    .servicehero1col{
        padding: 1rem 0rem;
    }
    .servicehero1{
        padding: 0rem 1rem;
    }

    .serviceinterior{
        display: block;
    }

    .sercol1 {
         padding-left: 0;
    }
    .homeservicecard{
        gap: 40px !important;
        margin: 3rem 1rem !important;
    }
}
@media screen and (max-width:400px) {
    .serviceinterior .service-slide{
        width: 90%;
        height: 50vh;
    }
    .sercol1 {
        padding: 0rem;
    }
    .sercol2{
        padding: 1rem 1rem;
    }
    .homeservice{
        padding: 2rem 1rem!important;
    }

    .homeservicecard{
        grid-template-columns: 1fr !important;
    }

    .homeservicecard .homesercardcol{
        width: 100%;
        padding: 1rem 1rem;
        height: auto;
    }
    .serviceabtinner{
        padding: 1rem 1rem!important;
    }
    .serviceabitinnercol .row1{
        flex-direction: column;
    }
    .serviceabitinnercol .header2{
        font-size: 24px!important;
    }
    .serviceabitinnercol1 .servblurb1{
        padding: 1rem 1rem;
        align-items: self-start;
    }
    .serviceabitinnercol1 .servblurb1 p{
        text-align: left;
    }
    .serviceabitinnercol1 .servblurb1 .smallhead{
        font-size: 24px!important;
    }
    .serviceabitinnercol .row1 .col2{
        text-align: left!important;
    }
    .serviceabitinnercol .row1{
        margin: 1rem 0rem 1rem 0rem !important;
    }

}