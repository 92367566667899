
  .awardwiningblurb{
    margin-top: 2rem;
    display: flex;
   gap: 25px;
  }
  .awardandwinnig h6{
    font-size: 34px;
    margin: 1rem 0rem;
  }
  .awardandwinnig .header2{
    font-size: 46px;
    font-weight: 300;
  }
  .awardandwinnig p{
    font-size: 15px;
    font-weight: 350;
    margin-bottom: 10px;
    line-height: 30px;
    letter-spacing: 0.75px;
  }
  .aw-blurbcontent{
    margin-left: 10px;
    width: 60%;
  }
  .awardwiningblurb .blurb{
    align-items: center;
    width: 50%;
    display: inline-flex;
    border:1px solid #afa89a4b;
    border-radius: 50px;
    padding: 5px;
    line-height: 1.5rem;
    font-family: "Montserrat", sans-serif;
  }
  .awardwiningblurb .blurb h6{
    color: #554F49;
    font-size: 18px;
    font-family: Montserrat;
    font-weight: 400;
    line-height: 96.6%;
    letter-spacing: -0.18px;
  }
  .awardwiningblurb .blurb p{
    margin-bottom: 0px;
    color: #EC9B4A;
    text-transform: uppercase;
    font-size: 11px;
  }

  .awardwiningblurb .aw-blurbimg{
    width: 70px;
    height: 70px;
     background-color: #4C4028;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .awardwiningblurb img{
    width: 25px;
    height: 25px;
  }




  @media screen and (max-width:1024px){
    .awardwiningblurb .aw-blurbimg{
      width: 50px;
      height: 50px;
    }
    .aw-blurbcontent h5, .aw-blurbcontent p{
      text-align: center !important;
    }
  }

  @media screen and (max-width:400px){
    .awardandwinnig .header2 {
      font-size: 27px;
    }

    .awardandwinnig h6{
      font-size: 27px;
    }

  }