nav {
    font-family: "Montserrat", sans-serif;  
    display: flex;
    align-items: center;
    padding: 15px 2rem;
    justify-content: space-between;
    box-shadow: 0px 4px 16.1px 0px rgba(0, 0, 0, 0.07);
    background-color: #fff;
}

nav.scrolled {
    background-color: rgba(255, 255, 255, 0.9); /* Slightly transparent background when scrolled */
}

nav ul {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 20px;
}

nav ul li {
    display: inline-block;
}

nav ul li a {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    color: var(--black);
    letter-spacing: 1px;
    font-weight: 500;
    text-decoration: none;
}

.navbtn-container {
    display: flex;   
    gap: 10px;
    margin-left: 20px;
}

.navbtn {
    font-family: "Montserrat", sans-serif;  
    text-transform: uppercase;
    padding: 8px 12px 8px 13px;
    border-radius: 35px;
    border: none;
    cursor: pointer;
    background-color: #4d1030;
    border: 1px solid #4d1030;
}

.navbtn a {
    font-size: 14px;
    color: #FFF;
    text-decoration: none;
}

.navbtn span {
    vertical-align: middle;
    margin-right: 5px;
}

.humbargur {
    display: none;
    font-size: 2rem;
    cursor: pointer;
    color: var(--yellow);
}

.active a {
    color: var(--rose);
    position: relative;
    transition: width 0.3s ease-in-out;
}

.active a::after {
    content: '';
    width: 100%;
    height: 2px;
    position: absolute;
    background-color: #CC8835;
    bottom: -30%;
    left: 0;
}

.active a::before {
    content: '';
    width: 50%;
    height: 2px;
    position: absolute;
    background-color: #CC8835;
    bottom: -60%;
    left: 0;
}
.dropdown-content .active a::before{
    content: '';
    width: 35%;
    height: 2px;
    position: absolute;
    background-color: #CC8835;
    bottom: -10%;
    left: 15px;    
}
.dropdown-content .active a::after{
    content: '';
    width: 89%;
    height: 2px;
    position: absolute;
    background-color: #CC8835;
    bottom: 10%;
    left: 15px;
}
.dropdown.active .dropdown-content a::before{
    display:none;
}
.dropdown.active .dropdown-content a::after{
    display:none;
}

/* For desktop view */
.navbtn-container {
    display: block; /* Always display on desktop */
}

.logo {
    display: flex;
    align-items: center;
}

.logo img {
    width: 54px;
    height: 54px;
}

.logo h1 {
    color: #4F0B30;
    font-family: "Montserrat";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; 
    letter-spacing: 0.18px;
}

.logo-link {
    text-decoration: none;
}


.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown div:not(:last-child) {
    margin-bottom: 10px;
}

.dropdown-content {
    visibility: hidden; 
    opacity: 0; 
    position: absolute;
    line-height: 1.5rem;
    left: 0;
    right: auto;
    width: max-content;
    background-color: white;
    z-index: 9999;
    box-sizing: border-box;
    color: black;
    transform: translateY(-10px);
    transition: opacity 0.5s ease, transform 0.5s ease, visibility 0s 0.5s; 
    padding: 30px 20px 20px 20px;
}



.dropdown:hover .dropdown-content {
    visibility: visible; 
    opacity: 1; 
    transform: translateY(0); 
    transition-delay: 0s;
}

.dropdown svg {
    vertical-align: middle;
}

.dropdown div, .submenu p {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    color: var(--black);
    letter-spacing: 1px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
}

.dropdown div:hover, .submenu p:hover {
    color: var(--rose);
}

.has-submenu {
    position: relative;
    padding-right: 10px; /* Add padding to avoid gaps */
}

.submenu {
    visibility: hidden; /* Use visibility instead of display for smoother transitions */
    opacity: 0;
    position: absolute;
    left: 100%;
    top: 0;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    z-index: 1000;
    transform: translateY(-10px);
    transition: opacity 0.5s ease, transform 0.5s ease, visibility 0s 0.5s; /* Delay hiding */
    width: max-content;
    margin-left: 10px;
}

.submenu p:not(:last-child) {
    margin-bottom: 10px;
}

/* Show submenu on hover */
.has-submenu:hover .submenu,
.has-submenu:focus-within .submenu {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0s; 
}

/* Styles for clickable dropdown items */
.dropdown-content div a, .submenu p a {
    display: block; 
    padding: 10px 15px; 
    color: inherit;
       text-decoration: none; 
}

.dropdown-content div a:hover, .submenu p a:hover {
    background-color: rgba(204, 136, 52, 0.493);
    color: #000;
}
.dropdown-content .active .dropdown-content{
    display: none;
}

/* For mobile view */
@media (max-width: 768px) {
     .dropdown-content {
        display: none; /* Hide dropdown by default */
    }
    
    /* Show dropdown when it's active */
    .dropdown-content.show {
        display: block; /* Show the dropdown when it has the 'show' class */
    }
    
    /* Optional: Add a transition effect */
    .dropdown-content {
        transition: all 0.3s ease; /* Smooth transition for dropdown */
    }
    .navbtn-container {
        display: none;
    }

    .navbtn-container-mobile {
        display: block;
        text-align: center;
        margin-top: 1rem;
    }

    .hamburger {
        cursor: pointer;
        font-size: 2rem;
        position: absolute;
        right: 20px;
        top: 20px;
    }

    .navlinkactive {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

/*media query--*/
@media screen and (max-width: 1024px) {
    nav {
        overflow-x: hidden;
        padding: 15px 2rem;
    }

    .humbargur {
        display: block;
    }

    nav ul li {
        display: block;
        padding: 20px 0px;
        text-align: center;
        width: 100%;
    }

    nav ul {
        position: absolute;
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        top: 6%;
        right: 100%;
        background-color: var(--white);
        z-index: 99;
        text-align: center;
        align-items: center;
        transition: all 0.5s;
        margin: 2rem 0;
    }

    .navbtn-container {
        margin-left: 0px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-top: 20px;
    }

    .navlinkactive {
        right: 0;
        margin: 2rem 0;
        padding: 1rem 0;
    }

    .humbargur {
        font-size: 1.8rem;
    }

    .navbtn-container {
        display: none; /* Hide the phone number button on tablets and mobile */
    }
    .dropdown-content {
        left: 50%; /* Center on mobile */
        transform: translate(-50%, -10px) !important; /* Center horizontally and adjust vertical translation */
        margin-top: 20px;
    }

    .right-arrow{
        transform: rotate(90deg);
    }

    .submenu{
        left: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        margin-left: 0;
        margin-top: 10px; 
    }

}

@media screen and (max-width: 368px) {
    nav {
        width: 100%;
        overflow-x: hidden;
    }
}
