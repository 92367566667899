
 

  /*---media query--*/
  @media screen and (max-width:900px){
    .slider{
        height: 70vh;
    }
    .heroslidercontent{
        bottom: 30%;
    }
    .heroslidercontentleft h2{
        width: 100%;
    }

  }
  @media screen and (max-width:428px){
    .slider{
        height: 60vh;
    }
    .heroslidercontent{
        display: flex;
        gap: 15px;
        padding: 20px;
        flex-direction: column;
        align-items: normal;
        bottom: 35%;

    }
    .heroslidercontentleft h2{
        width: 100%;
        font-size: 24px;
        line-height: 2.4rem;
    }
  }