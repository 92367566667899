#Testimonial {
  overflow: hidden;
  background-color: #f9d999;
  padding: 5rem 5rem;
  background-image: url("../../../images/quate.png");
  background-position: top;
  background-repeat: no-repeat;
  background-position-x: 5rem;
}
.row-testimonial {
  display: flex;
  justify-content: space-between;
}

.col-testimonial .get-insight {
  width: 65%;
  color: #36332f;
  margin-top: 20px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-style: normal;

  line-height: 1.6rem;
  letter-spacing: 0.02rem;
}
.col-testimonial h6 {
  color: #36332f;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1.26px;
  margin-top: 20px;
}
.satisfied-cutomer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff26;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 50px;
  padding: 3px;
}
#Testimonial .header2 {
  font-size: 49px;
  color: #36332f;
  margin-bottom: 20px;
  font-weight: 400;
}
.satisfied-cutomer span {
  font-weight: 600;
  background-color: #fff;
  color: #36332f;
  margin-right: 25px;
  padding: 22px 18px;
  font-size: 21px;
  border-radius: 50px;
  letter-spacing: 1px;
}
.satisfied-cutomer p {
  color: #36332f;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.18px;
  margin-right: 60px;
}
/*--testimonial card--*/
.testimonial-card {
  justify-content: space-between;
  display: flex;
  gap: 20px;
  margin-top: 20px;
}
.testimonial-card .checked {
  color: #f9d999;
  margin: 0px 3px;
}
.rating {
  color: #d9a365;
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}
.testimonial-card .card {
  width: 360px;
  height: 365px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  background-color: #f9d999;
}
.card-content-inner {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  transition: top 0.6s ease-in-out;
  border-radius: 20px;
  padding: 2rem;
}
.card-content-inner p {
  font-family: "Encode Sans Expanded";
  font-size: 12.5px;
  line-height: 2em;
}
.card:hover .card-content-inner {
  top: -100%;
}
.testimonial-card .card-content {
  position: relative;
  height: 100%;
  background-color: #fafafa;
  border-radius: 25px;
  overflow: hidden;
  z-index: 0;
}

.card-content .card-hoverimg {
  z-index: -1;
  position: absolute;
  bottom: -100%;
  left: 0;
  transition: bottom 0.6s ease-in-out, transform 0.6s ease-in-out;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.card:hover .card-content .card-hoverimg {
  bottom: 0;
  object-fit: cover;
  transform: scale(1.05);
}

.testimonial-card .card-content::after {
  content: "";
  position: absolute;
  border-radius: 50px;
  background-color: #ff000000;
  box-shadow: -22px 33px 0px #f9d999;
  width: 96px;
  height: 66px;
  bottom: 19%;
  left: 0%;
}
.testimonial-card .card-content::before {
  overflow: hidden;
  content: "";
  position: absolute;
  border-radius: 50px;
  box-shadow: -25px 24px 0px #f9d999;
  width: 71px;
  height: 58px;
  bottom: 0%;
  right: 25%;
  z-index: 0;
}
.quatation {
  position: absolute;
  bottom: 5%;
  right: 16%;
}
.testimonial-card .card-authur {
  background-color: #f9d999;
  z-index: 5;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 56%;
  z-index: 0;
  border-top-right-radius: 25px;
  border-bottom-left-radius: 15px;
  border-top: 10px solid #f9d999;
  border-right: 10px solid #f9d999;
}
.testimonial-card .card-author-inner {
  background-color: #fbe5ba;
  display: flex;
  border-radius: 15px;
  padding: 6px 10px;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  z-index: 5;
  gap: 5px;
}
.card-authur .author-name {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.card-authur .autor-img img{
  width: 45px;
  height: 45px;
}

.card-authur p {
  color: #292737;
  font-family: "Montserrat", sans-serif;
  font-size: 12.5px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px; /* 120% */
  letter-spacing: 0.125px;
}




@media screen and (max-width: 1280px) {
  #Testimonial {
    padding: 4rem 2rem;
  }

  .testimonial-card .card {
    width: 330px;
  }

  .swiper-button-prev {
    margin-right: 3%;
  }
}


@media screen and (max-width: 800px) {
  #Testimonial {
    padding: 4rem 2rem;
  }
  .row-testimonial {
    display: flex;
    flex-direction: column;
  }
  .col-testimonial .get-insight {
    width: 100%;
  }
  .testimonial-card {
    margin-top: 20px;
  }
  .satisfied-cutomer {
    margin-top: 30px;
    width: 55%;
  }

  .swiper-button-prev {
    margin-right: 4%;
  }

  .testimonial-card .card {
    width: 330px;
  }
  .swiper-wrapper{
    gap: 0 !important;
  }
  .card-authur p {
    font-size: 10px;
  }
}

@media screen and (max-width: 480px) {
  #Testimonial {
    padding: 2rem 1rem;
  }
  .swiper-button-prev {
    margin-right: 18%;
  }
  .satisfied-cutomer {
    width: 100%;
  }


  #Testimonial .header2 {
    font-size: 27px;
    color: #36332f;
    margin-bottom: 20px;
    font-weight: 400;
  }

  .testimonial-card .swiper-button-next {
    width: 30px;
    height: 30px;
  }
  .testimonial-card .swiper-button-prev {
    width: 30px;
    height: 30px;
  }


}

/*--swiper code---*/
.testimonial-card .swiper-wrapper {
  padding-top: 6rem;
}
.testimonial-card .swiper-button-next {
  top: 6% !important;
  /* padding: 7px 21px !important; */
  width: 50px;
  height: 50px;
  background-color: #36332f;
  color: #fff !important;
  font-size: 14px;
  border-radius: 50px;
}
.testimonial-card .swiper-button-prev {
  top: 6% !important;
  /* padding: 7px 21px !important; */
  width: 50px;
  height: 50px;
  background-color: #36332f;
  color: #fff !important;
  font-size: 14px;
  border-radius: 50px;
  right: 5% !important;
  left: auto !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 20px !important;
}


.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
  box-sizing: content-box;
  justify-content: space-evenly;
  gap: 20px;
}

