.homeservice{
    padding: 5rem 2rem;
    text-align: center;
    background-color: #fcfcfc;
}
.homeservice .header2{
    font-weight: 400;
    margin-bottom: 15px;
}
.homeservice .hr{
    height: 2px;
    width: 4%;
    margin: 2rem auto;
    background-color: #D18F44;
}
.homeservice p {
    font-size: 13px;
}
.homeservicecard{
    /* margin-top: 3rem;
    display: flex;
    justify-content: space-between; */
    margin-top: 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 70px;
    margin: 3rem 6rem;
    }

    .homesercardcol{
        background-color: #fff;
        text-align: left;
        overflow: hidden;
        /* width: 24%; */
        width: 100%;
        padding: 2rem 1rem;
        border-radius: 15px;
        /* height: 550px; */
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .homesercardcol .smallhead{
        text-transform: uppercase;
        margin-bottom: 20px;
    }
    .homesercardcol p{
        margin-bottom: 20px;
        flex: 1;
    }
    .homesercardcol .btn1{
        /* margin-bottom: 50px; */
        margin-top: auto;
        width: 40%;
        text-align: left;
        padding-left: 50px;
    }
    .homesercardcol img{
        height: 250px;
        width: 100%;
        border-radius: 15px;
    }


    @media screen and (max-width:1024px){
        .homesercardcol .btn1{
            width: 100%;
            text-align: center;
            padding-left: 0;
        }
    }


