.chooseheader {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.5rem;
}
.whychooseus {
  background-color: #fcfcfc;
  padding: 0 3rem;
}
.chooseheader h3 {
  color: #3b3b3b;

  font-family: "Montserrat", sans-serif;
  margin-bottom: 15px;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.01313rem;
}
.chooseheader p {
  color: #323232;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  width: 40%;
  letter-spacing: 0.00875rem;
}
.chooseheader .header2 {
  font-size: 34px;
  font-weight: 400;
}
.choose-inner {
  background-color: #e7a14b17;
  padding: 3.5rem 2rem 2rem 2rem;
  border-radius: 25px;
}
.chose-content {
  margin-top: 2rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between; /* Ensure equal space between columns */
  border-radius: 1.375rem;
  background: #de9439;
  backdrop-filter: blur(23.5px);
}

.choose-content-col {
  flex: 1;
  gap: 20px;
  display: flex;
  flex-direction: column;
  margin: 0 0.5rem;
}

.choose-content-col .blurb {
  align-items: center;
  justify-content: center;
  margin: 0px;
  flex: 1;
  border-radius: 1.1875rem;
  background: #d7a363b4;
  backdrop-filter: blur(23.5px);
}
.choose-content-col .blurb p {
  font-family: "Montserrat", sans-serif;
  font-size: 19px;
  text-transform: uppercase;
  color: #fff;
  margin-left: 15px;
}
.choose-content-col .blurb img {
  width: 13%;
}
.choose-details {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.choose-contactus {
  position: absolute;
  bottom: -100%;
  width: 96%;
  background: rgba(190, 189, 189, 0.041);
  backdrop-filter: blur(14.199999809265137px);
  transition: bottom 0.3s ease-in-out;
  height: 90%;
  padding: 1rem;
  border-radius: 15px;
  margin: 10px 10px;
}

.choosecontact-inner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dotted rgba(0, 0, 0, 0.452);
}

.choose-details:hover .choose-contactus {
  bottom: 0;
}
.choosecontact-inner button {
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;
  padding: 15px 30px;
  border-radius: 15px;
  border: none;
  color: #fff;
  background: rgba(23, 23, 23, 0.116);
  backdrop-filter: blur(14.199999809265137px);
}

@media screen and (max-width: 1024px) {
  .whychooseus {
    padding: 0rem 2rem;
  }

  .choose-content-col .blurb {
    padding: 1rem 2rem;
  }

  .choose-details img{
    width: 100%;
  }
}

@media screen and (max-width: 820px) {
  .whychooseus {
    padding: 0rem 2rem;
  }
  .choose-content-col {
    overflow: hidden;
  }
  .choose-inner {
    padding: 2rem 1rem;
  }
  .choose-content-col:last-child {
    border-radius: 15px;
  }
  .chooseheader p {
    margin-left: 0px;
  }
  .choose-content-col .blurb img {
    width: 22%;
  }

  .choose-content-col .blurb p {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    margin-left: 0;
}
}


@media screen and (max-width: 480px) {
  .whychooseus {
    padding: 2rem 1rem;
  }
  .chose-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 1rem 0.3rem;
  }
  .choose-content-col {
    gap: 10px;
  }
  .chooseheader {
    display: flex;
    flex-direction: column;
  }
  .chooseheader p {
    width: 100%;
    margin-top: 15px;
  }
  .choose-content-col .blurb {
    padding: 1rem 0rem;
  }

  .chooseheader .header2 {
    font-size: 27px;
  }

  .choose-content-col .blurb p {
    font-size: 15px;
}
}
