
.quoteform {
  width: 375px;
  background-color: #fff;
  padding: 30px 25px;
  border: 5px solid #cc8834;
  border-radius: 27px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
}


.quoteform form {
  width: 100%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}
.quoteform h3 {
  width: 100%;
  text-align: left;
  z-index: 10;
  color: #3b352e;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.16px;
}
.quoteform form .inputfeild {
  font-family: "Montserrat", sans-serif;
  outline: none;
  border-radius: 8px;
  padding: 15px 20px;
  border: 1px solid #00000017;
  color: #3a3834;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.39px;
}
.quoteform form select {
  font-family: "Montserrat", sans-serif;
  outline: none;
  border-radius: 8px;
  padding: 15px 20px;
  border: 1px solid #00000017;
  color: #3a3834;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.39px;
}

.quoteform form .submit {
  font-family: "Montserrat", sans-serif;
  background-color: #cc8834;
  border-radius: 1.8125rem;
  border: none;
  font-size: 1rem;
  padding: 15px 32px;
  color: #fafafa;
  cursor: pointer;
  position: relative;
}
.quoteform form .submit span {
  padding: 12px 11px 8px 12px;
  color: #fff;
  background: #edc79d96;
  margin-left: 33px;
  border-radius: 41px;
  position: absolute;
  right: 7px;
  top: 5px;
}
@media screen and (max-width: 368px) {
  .quoteform {
    width: 300px;
  }
}
