.galleryhero{
    background-image: url("../../images/gallery/herogallery.png");
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.galleryhero h1{
    color: #fcfcfcb7;
text-align: center;
font-family: "Montserrat", sans-serif;
font-size: 140px;
font-style: normal;
font-weight: 400;
line-height: 54px; 
letter-spacing: 2.8px;
text-transform: uppercase;
}
.topgallry{
    margin-top: -55px!important;
    width: 50%;
    margin: 0px auto;
    text-align: center;
    border-radius: 27px;
background: #FCFCFC;
border-top: 5px solid #9a939196;
padding: 2rem 4rem;
display: flex;
flex-direction: column;
gap: 25px;
}

    .contact-hr {
        background-color: #D9A365;
        width: 2px;
        height: 25px;
        margin: 0 auto; 
        padding: 0; /* Removes any padding if applied */
        display: inline-block; /* Ensures it takes only necessary space */
    }
    
    .topgallry h4 {
        border-radius: 24px;
        background: #d9a36525;
        color: #D9A365;
        font-family: "Encode Sans Expanded", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 5.04px;
        text-transform: uppercase;
        padding: 1rem;
        align-self: center; /* Center the heading within the flex container */
    }
.topgallry .header2{
    line-height: 54px;
    font-weight: 400;
    font-size: 37px;
}

/*gallery ani*/
.galley-row-content{
    display: flex;
    gap: 15px;
      
}
.gallery-row{
    padding: 3rem 5rem;
}
.galllry-col{
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.galllry-col p{
    cursor: pointer;
}
.galleryserv{
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 15px;
}
.galleryserv p{
    color: #D9A365;
font-family: "Encode Sans Expanded";
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.14px;
text-transform: uppercase;
}





@media screen and (max-width:820px) {
    .galleryhero {
        height: 50vh;
    }
    .topgallry{
        width: 95%;
    }
    .gallery-row {
        padding: 2rem 2rem;
    }
   
}
@media screen and (max-width:400px) {
    .topgallry .header2{
        font-size: 24px;
        line-height: 2rem;
    }
    .topgallry{
        padding: 2rem 1rem;
    }
    .galleryhero h1{
        font-size: 54px;
    }
    .gallery-row {
        padding: 1rem 1rem;
           }
           .galley-row-content{
            flex-direction: column;
           }
    .galleryserv{
        justify-content: start;
    }
.gallery-row .header2{
    font-size: 24px;
}
.galleryserv img{
    width: 25%;
}
.gallery-row .carousel-container {
    margin: 1rem 0rem;
}
.gallery-row .logo-item{
    height: 150px;
   
}
}