.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 8px;
  width: 60%;
  max-width: 800px;
  height: 95vh;
  overflow-y: auto; /* Allows vertical scrolling if content overflows */
  position: relative;
  text-align: center;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
}



.popuprow {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  gap: 50px;
  flex-wrap: wrap; 
}

.popuprow img {
  max-width: 100%;
  max-height: 70vh;
  object-fit: contain; 
  margin-bottom: 10px;
}

.popuprow form {
  gap: 0px;
  flex: 1; 
}



@media screen and (max-width:800px){
  .popup-content {
    width: 80%;
 }

 .popuprow img {
  width: 23rem;
  max-height: max-content;
 }
}


@media screen and (max-width:480px){
  .popup-content {
    padding: 40px 0;
    width: 90%;
}

.popuprow {
  margin-top: 0;
}

.popuprow img {
  display: none;
}
}