.package {
  background-image: url("../../images/packagebg.png");
  background-size: cover;
  background-position: center;
  padding: 0 3rem 2rem 3rem;
  background-color: #fcfcfc;
}
.package h2 {
  margin-bottom: 15px;
  text-align: center;
  font-size: 34px;
  font-weight: 400;
}
.package .hr {
  width: 4%;
  background-color: #d18f44;
  height: 2px;
}

.package .pricesubhead {
  margin-bottom: 25px;
  color: #272727;
  text-align: center;
  font-family: Montserrat;
  font-size: 0.8125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.02438rem;
}
.package-price {
  display: flex;
  justify-content: center;
  gap: 35px;
  padding: 4rem 3rem;
}
.pricelist {
  width: 420px;
  height: auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--white);
  padding: 0.6rem 0.6rem 1rem 0.6rem;
  border-radius: 19px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: relative;
}
.pricelist img {
  border-radius: 15px;
  height: 250px;
}

.bhk {
  border-radius: 50px;
  background-color: #fff;
  box-shadow: #64646f33 0px 7px 29px 0px;
  color: #ef9c3d !important;
  font-family: "Montserrat", sans-serif;
  font-size: 1.0625rem;
  letter-spacing: 0.01063rem;
  display: inline-block;
  padding: 6px 25px;
  margin-top: -40%;
  align-items: end;
  position: absolute;
  right: 10%;
}

.pricelist ul {
  list-style: none;
  padding-left: 15px;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.pricelist ul li {
  color: #494b4f;
  font-family: "Encode Sans Expanded";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.12px;
}
.pricelist ul li::before {
  content: "✓";
  padding: 2px 6px;
  border-radius: 50px;
  margin-right: 15px;
  background-color: #ef9c3d1f;
  font-size: 10px;
  vertical-align: middle;
  color: #ef9c3d;
}
.pricelist .btn1 {
  border-radius: 11px;
  text-align: left;
  width: 100%;
}

.price {
  margin-left: 15px;
  margin-top: 35px;
  color: #ef9c3d;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 1.4375rem !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.04313rem;
}
.price span {
  color: #e0787a;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.0525rem;
  text-decoration-line: line-through;
}
.price i {
  font-size: 17px;
  vertical-align: baseline;
}

.pricebutton{
    border-radius: 15px !important;
}


@media screen and (max-width: 800px) {
  .package {
    padding: 2rem 1rem;
  }
  .pricelist {
    width: 350px;
  }
  .bhk {
    margin-top: -55%;
  }
  .pricelist2 .bhk {
    margin-top: -55%;
  }
  .package-price {
    padding: 2rem 0rem;
    margin: 1%;
  }
}
@media screen and (max-width: 480px) {
  .package-price {
    display: flex;
    padding: 0rem 0rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .pricelist {
    width: 325px;
    margin-top: 3rem;
  }
  .bhk {
    margin-top: -60%;
  }
  .pricelist2 .bhk {
    margin-top: -60%;
  }

  .package .hr {
    width: 8%;
  }

  .package {
    padding: 2rem 1rem;
  }
}




