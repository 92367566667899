
/* homequotation.css */
.homequtation {
  position: relative;
  z-index: 0;
  font-family: "Montserrat", sans-serif;
  display: flex;
  overflow: hidden;
  justify-content: start;
  align-items: center;
  background: #fcfcfc;
}

.homequationslider {
  position: relative;
  overflow: hidden;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 4rem;
}

.homequationslider::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-bottom-right-radius: 80px;
  border-top-right-radius: 80px;
  width: 60%;
  height: 100%;
  background-color: #f2f2f2;
  z-index: 0;
}

.homequtation .slide {
  z-index: 2;
  border: 13px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  width: 80%;
  height: 100%;
}

.quote-slider {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
  height: 100%;
}

.quote-slider-inner {
  display: flex;
  height: auto;
}

.h-slide {
  min-width: 100%;
  height: 100%;
}

.h-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container */
}

.homequotationcontent {
  width: 50%;
  padding-right: 4rem;
}
.homequotationcontent .header2 {
  font-size: 50px;
  font-weight: 300;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.homequotationcontent p {
  font-family: "Montserrat", sans-serif;
  color: rgba(44, 52, 64, 0.37);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  padding: 10px 25px;
  border-radius: 31px;
  display: inline-block;
  background: rgba(245, 245, 245, 0.99);
}

/*--form css--*/
.homequotationcontent form {
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.homequotationcontent form input {
  width: 45%;
  outline: none;
  border-radius: 0.5625rem;
  border: 1px solid rgba(0, 0, 0, 0.09);
  opacity: 0.7;
  background: rgba(245, 245, 245, 0.99);
  padding: 20px 25px;
  font-size: 15px;
  color: rgba(54, 57, 63, 0.64);
}

.homequotationcontent .btn1 {
  width: 94%;
  padding: 15px 0;
  color: #fafafa;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.homequotationcontent form .requsting {
  width: 93%;
  border-radius: 0.5625rem;
  border: 1px solid rgba(0, 0, 0, 0.09);
  color: rgba(54, 57, 63, 0.64);
  font-size: 15px;
  padding: 15px 20px;
  display: flex;
}
.requsting label {
  width: 35%;
  color: rgba(54, 57, 63, 0.64);
}

.homequotationcontent form .requsting select {
  font-family: "Montserrat", sans-serif;
  outline: none;
  border: none;
  background: transparent;
  font-size: 15px;
  color: #36393f;
  width: 100%;
  
}
.homequotationcontent form input::placeholder {
   font-family: "Montserrat", sans-serif!important;
 
}
@media screen and (max-width: 800px) {
  .homequtation {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-top: 3rem;
  }

  .homequationslider {
    position: relative;
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 4rem;
  }

  .homequationslider::before {
    top: 0;
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    width: 100%;
    height: 60%;
  }

  .slide {
    width: 90%;
    height: auto;
  }
  .quote-slider-inner {
    height: auto;
  }

  .homequotationcontent {
    width: 100%;
    padding: 0 2rem;
  }

  .homequotationcontent form {
    gap: 28px;
}

  .homequotationcontent form input {
    width: 48%;
  }

  .homequotationcontent form .requsting {
    width: 100%;
  }

  .homequotationcontent .btn1 {
    width: 100%;
}
}



@media screen and (max-width: 480px) {
  .homequationslider {
    width: 100%;
    padding: 60px 1rem;
  }

  .homequationslider::before {
    border-radius: 20px;
  }
  
  .homequtation {
    margin: 0 10px;
    border-radius: 20px;
  }

  .homequtation .slide {
    width: 903px;
    height: 100%;
}

  .homequotationcontent form input {
    width: 100%;
    margin: 10px 0px;
  }
  .homequotationcontent h2 {
    margin-bottom: 5px;
    font-size: 1.8rem;
  }

  .homequotationcontent .header2{
    font-size: 27px !important;
    margin-bottom: 1rem;
  }

  .homequotationcontent {
    width: 100%;
    padding: 0 0.5rem;
  }

  
  /*--form css--*/
  .homequotationcontent form {
    width: 100%;
    flex-direction: column;
    gap: 5px;
  }
  .homequotationcontent form input {
    width: 100%;
    padding: 15px 20px;
    margin-bottom: 10px;
  }
  
  .homequotationcontent .btn1 {
    width: 94%;
    padding: 15px 0;
    color: #fafafa;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  
  .homequotationcontent form .requsting {
    width: 100%;
    padding: 15px 20px;
  }
  .requsting label {
    width: 100%;
  }

  .homequotationcontent .btn1 {
    width: 100%;
    padding: 15px 0;
  }
  

  
}
